import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout"
import SEO from "../components/seo";
import AboutSection from '../components/about-section';

const AboutPage = ({ data, pageContext }) => {
    return (
        <Layout {...pageContext}>
            <SEO 
                lang={pageContext.lang}
                path={pageContext.pageUrl} 
                title={data.seoTitleText}
                description={data.seoDescriptionText}
                image={data.photoShare && data.photoShare.childImageSharp ? data.photoShare.childImageSharp.resize.src : null}
            />
            <div id="header">
                <div className="section-content">
                    <div className="big-text">
                        <h1 className="t-left f-large normal" dangerouslySetInnerHTML={{ __html: data.aboutHeroTitle }} />
                        <div className="separator-small"></div>
                        <h2 className="t-left f-medium normal" dangerouslySetInnerHTML={{ __html: data.aboutHeroSubtitle }} />
                        <h3 className="t-left f-normal normal" dangerouslySetInnerHTML={{ __html: data.aboutHeroText }} />
                        <br />
                        <p className="t-left f-normal normal link-underline" dangerouslySetInnerHTML={{ __html: data.aboutHeroContact }} />
                    </div>
                </div>
            </div>
            <div id="description">
                <div className="section-content">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="img-fixed-responsive">
                                <Img style={{ maxWidth: '100%', height: 'auto', paddingTop: '100%', marginTop: '8px' }} fixed={data.photo.childImageSharp.fixed} alt="Patricia Langkemper" />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="brief no-margin t-left link-underline" dangerouslySetInnerHTML={{ __html: data.aboutText }} />
                        </div>
                    </div>
                </div>
            </div>
            <AboutSection data={data} />
        </Layout>
    );
}



export const pageQuery = graphql`
    query AboutPage($lang: String!) {
        seoTitleText: getTranslation(lang: $lang, key: "about_seo_title")
        seoDescriptionText: getTranslation(lang: $lang, key: "about_seo_description")
        aboutHeroTitle: getTranslation(lang: $lang, key: "about_hero_title")
        aboutHeroSubtitle: getTranslation(lang: $lang, key: "about_hero_subtitle", html: true, removeWrapper: true)
        aboutHeroText: getTranslation(lang: $lang, key: "about_hero_text", html: true, removeWrapper: true)
        aboutHeroContact: getTranslation(lang: $lang, key: "about_hero_contact", html: true, removeWrapper: true)
        aboutText: getTranslation(lang: $lang, key: "about_text", html: true)

        photo: file(relativePath: { eq: "patricia-langkemper.jpeg" }) {
            childImageSharp {
                fixed(width: 780, height: 780, quality: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }

        photoShare: file(relativePath: { eq: "patricia-langkemper.jpeg" }) {
            childImageSharp {
                resize(width: 1200, height: 630, quality: 80) {
                    src
                }
            }
        }
        ...ServicesFragment
    }
`

export default AboutPage